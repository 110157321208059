import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import type {} from "@redux-devtools/extension";

export type CartEntry = {
    productId: string;
    quantity: number;
    priceId: string;
    productPageContent: any;
    makers: any[];
    unitPrice: number;
};

interface LocalState {
    cart: CartEntry[];
    add: (entry: CartEntry) => void;
}

const useLocalStore = create<LocalState>()(
    devtools(
        persist(
            (set) => ({
                cart: [],
                add: (entry) =>
                    set((state) => ({
                        cart: [...state.cart, entry],
                    })),
            }),
            {
                name: "local-store",
            }
        )
    )
);

export default useLocalStore;
