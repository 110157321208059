"use client";

import React from "react";
import useLocalStore from "@/store";
import { PiShoppingCartLight } from "react-icons/pi";

export default function CartIcon() {
    const cart = useLocalStore((state) => state.cart);

    let totalQuantity = 0;
    cart.forEach((entry) => (totalQuantity += entry.quantity));

    return (
        <div className="relative">
            <PiShoppingCartLight size={18} />
            {totalQuantity > 0 && (
                <span className="absolute top-[-2px] end-[-3px] px-[6px] py-[0.5px] inline-flex align-middle items-center rounded-full text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-cyan-900 text-white font-serif">
                    {totalQuantity}
                </span>
            )}
        </div>
    );
}
