import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Crimson_Text\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-crimson-text\",\"weight\":[\"400\",\"600\",\"700\"]}],\"variableName\":\"crimson\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Baskervville\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--font-baskerville\"}],\"variableName\":\"baskerville\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-montserrat\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Cormorant_Garamond\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-garamond\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"]}],\"variableName\":\"garamond\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/CartIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/PrelineScript.tsx");
